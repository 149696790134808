import React, { useCallback, useState } from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  const [id, setId] = useState(0);
  const [data, setData] = useState("");
  const callApi = useCallback(async () => {
    const content = await (await fetch(`/api/items/${id}`)).text();
    setData(content);
  }, [id]);
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        <div>
          Call Backend Api
          <div>
            <input type='number' value={id} onChange={(e) => setId(Number(e.target.value))}></input>
          </div>
          <p>{data}</p>
          <button onClick={callApi}>Click</button>
        </div>
      </header>
    </div>
  );
}

export default App;
